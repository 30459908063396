import React from 'react';
import TabNavItem, { TabNavData } from './TabNavItem';
import TabContext from './TabContext';
import { NavDirections } from '../../../../types/common';

interface TabNavProps {
  navData: TabNavData[];
  direction?: NavDirections;
  variant?: 'primary' | 'secondary';
}

function TabNav(props: TabNavProps) {
  const context = React.useContext(TabContext);
  const { navData, direction = 'start', variant = 'primary' } = props;

  if (!context) {
    throw new Error(
      'TabNav component must be defined as child of Tabs component'
    );
  }

  return (
    <ul
      className={`tab-nav tab-nav-${variant} justify-content-${direction}`}
      role="tablist"
    >
      {navData.map(item => (
        <TabNavItem
          key={item.id}
          id={item.id}
          title={item.title}
          selectedItemId={context.selectedItemId}
          onSelect={context.handleSelectItem}
        />
      ))}
    </ul>
  );
}

export default TabNav;
