import React from 'react';
import Link from 'next/link';
import Button from '../shared/Button';

function StartPage() {
  return (
    <div className="d-flex my-5">
      <div className="mx-auto text-center">
        <Link href="/signup">
          <a>
            <Button size="large" className="py-4 px-5">
              Start my page - It&#39;s Free
            </Button>
          </a>
        </Link>
        <h6 className="mt-3">Its Free and it takes less than a minute</h6>
      </div>
    </div>
  );
}

export default StartPage;
