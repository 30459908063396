import React from 'react';
import Link from 'next/link';
import Logo from './Logo';
import useLoggedinUserDetail from '../../hooks/models/useLoggedinUserDetail';
import { logout } from '../../services/authService';
import { useAuth } from '../../contexts/AuthContext';

interface HeaderProps {
  shaded?: boolean;
}

function Header({ shaded = false }: HeaderProps) {
  const { setAuth } = useAuth();
  const { data, error } = useLoggedinUserDetail();

  React.useEffect(() => {
    if (error && error.error === 'Invalid or Expired Token') {
      logout();
      setAuth(null);
    }
  }, [error, setAuth]);

  return (
    <header className={`bmt-header-wrapper${shaded ? ' bmt-shaded-header' : ''}`}>
      <div className="container bmt-header">
        <Logo />
        <nav className="bmt-nav">
          {data ? (
            <ul className="bmt-nav-list">
              <li className="bmt-nav-item">
                <Link href="/dashboard">
                  <a className="bmt-btn bmt-btn-primary">Dashboard</a>
                </Link>
              </li>
            </ul>
          ) : (
            <ul className="bmt-nav-list">
              <li className="bmt-nav-item">
                <Link href="/login">
                  <a className="bmt-btn bmt-btn-secondary bg-none">Log in</a>
                </Link>
              </li>
              <li className="bmt-nav-item">
                <Link href="/signup">
                  <a className="bmt-btn bmt-btn-primary">Sign up</a>
                </Link>
              </li>
            </ul>
          )}
        </nav>
      </div>
    </header>
  );
}

export default Header;
