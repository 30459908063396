import React from 'react';
import Tab, { TabProps } from './components/Tab';
import { TabNavData } from './components/TabNavItem';
import { TabContextProvider } from './components/TabContext';
import TabNav from './components/TabNav';
import { NavDirections } from '../../../types/common';

interface TabsProps {
  defaultActiveItemId: string;
  navBarDirection?: NavDirections;
  variant?: 'primary' | 'secondary';
  children: React.ReactNode;
}

function Tabs(props: TabsProps) {
  const { defaultActiveItemId, navBarDirection, variant, children } = props;

  const tabData: TabNavData[] = React.useMemo(() => {
    const data: TabNavData[] = [];

    React.Children.forEach(children, (child: React.ReactNode) => {
      if (!React.isValidElement<TabProps>(child)) {
        throw new Error('Tabs children must be vaild react element');
      }

      const elementChild: React.ReactElement<TabProps> = child;

      data.push({
        id: elementChild.props.id,
        title: elementChild.props.title,
      });
    });

    return data;
  }, [children]);

  return (
    <div className="tabs">
      <TabContextProvider defaultActiveItem={defaultActiveItemId}>
        <div className="tab-nav-wrapper">
          <TabNav variant={variant} navData={tabData} direction={navBarDirection} />
        </div>
        <div>{children}</div>
      </TabContextProvider>
    </div>
  );
}

Tabs.Tab = Tab;

export default Tabs;
