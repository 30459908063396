import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { BsHeartFill } from 'react-icons/bs';

import { Creator } from '../../../types/common';

function CreatorsCard(props: Omit<Creator, '_id'>) {
  const { imgLink, creatorName, creatorType, creatorInfo, noOfSupporters, profileLink } = props;

  return (
    <Link href={profileLink}>
      <div className="creators-card">
        <div className="creators-card-head">{creatorName}</div>
        <div className="creators-card-mid">
          <Image src={imgLink} width="100%" height="100%" />
          <p className="mt-2">{creatorType}</p>
        </div>
        <div className="creators-card-bottom">
          <p>{creatorInfo}</p>
          <div className="creator-supporter">
            <BsHeartFill /> <span className="supporter-num">{noOfSupporters} Supporters</span>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default CreatorsCard;
