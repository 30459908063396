import { CreatorsData } from '../types/common';

const creatorsData: CreatorsData = {
  creatorTypes: [
    { id: 'artists', title: 'Artists & Illustrators' },
    { id: 'photographers', title: 'Photographers & Videographers' },
    { id: 'writers', title: 'Writers' },
    { id: 'musicians', title: 'Musicians' },
    { id: 'developers', title: 'Developers' },
    { id: 'designers', title: 'Designers' },
  ],

  creators: {
    artists: [
      {
        _id: '1',
        creatorName: 'Mahnoor Nasir',
        creatorType: 'Vlogger',
        imgLink: '/assets/creators/artists/Mahnoor-Nasir.jpg',
        creatorInfo: 'Lifestyle and fashion vlogs on Youtube',
        noOfSupporters: 100,
        profileLink: 'https://getmeachai.com/mahnoor',
      },
      {
        _id: '2',
        creatorName: 'Rabia Hussain',
        creatorType: 'Fashion Model',
        imgLink: '/assets/creators/artists/Rabia-Hussain.jpg',
        creatorInfo: 'Modelling for established fashion brands',
        noOfSupporters: 92,
        profileLink: 'https://getmeachai.com/RabiaHussain',
      },
      {
        _id: '3',
        creatorName: 'Shayan Rasheed',
        creatorType: 'Influencer',
        imgLink: '/assets/creators/artists/Shayan-Rasheed.jpg',
        creatorInfo: 'Social media influencer and product reviewer',
        noOfSupporters: 44,
        profileLink: 'https://getmeachai.com/shayanrasheed',
      },
    ],

    photographers: [
      {
        _id: '1',
        creatorName: 'Shariq Shah',
        creatorType: 'Product Photographer',
        imgLink: '/assets/creators/photographers/Shariq-Shah.jpg',
        creatorInfo: 'Capturing brand’s message with my lens | Commercial product photographer',
        noOfSupporters: 97,
        profileLink: 'https://getmeachai.com/ShahSAHAB',
      },
      {
        _id: '2',
        creatorName: 'Obaid Javed',
        creatorType: 'Event Photographer',
        imgLink: '/assets/creators/photographers/Obaid-Jawed.jpg',
        creatorInfo: 'Corporate, Wedding and event photographer',
        noOfSupporters: 158,
        profileLink: 'https://getmeachai.com/Obaid',
      },
      {
        _id: '3',
        creatorName: 'Tayyaba Sajjad',
        creatorType: 'Wildlife Photographer',
        imgLink: '/assets/creators/photographers/Tayyaba-Sajjad.jpg',
        creatorInfo: 'Capturing wild moments all around the world',
        noOfSupporters: 200,
        profileLink: 'https://getmeachai.com/Tayyaba',
      },
    ],

    writers: [
      {
        _id: '1',
        creatorName: 'Abida Muqeem',
        creatorType: 'Copywriter',
        imgLink: '/assets/creators/writers/Abida-Muqeem.jpg',
        creatorInfo: 'Writing creative marketing copies for fashion brands.',
        noOfSupporters: 185,
        profileLink: 'https://getmeachai.com/amuqeem15',
      },
      {
        _id: '2',
        creatorName: 'Hamza Imran',
        creatorType: 'Novelist',
        imgLink: '/assets/creators/writers/Hamza-Imran.jpg',
        creatorInfo: 'Aspiring fiction writer, aiming to publish my own book',
        noOfSupporters: 210,
        profileLink: 'https://getmeachai.com/hamza78',
      },
      {
        _id: '3',
        creatorName: 'Rughees Ahmed',
        creatorType: 'Blogger',
        imgLink: '/assets/creators/writers/Rughees-Ahmed.jpg',
        creatorInfo: 'Business blogging for my personal blog as well as freelance projects',
        noOfSupporters: 23,
        profileLink: 'https://getmeachai.com/Raghees',
      },
      {
        _id: '4',
        creatorName: 'Asfara Amir',
        creatorType: 'Content Writer',
        imgLink: '/assets/creators/writers/Asfara-Amir.jpg',
        creatorInfo: 'Business blogging for my personal blog as well as freelance projects',
        noOfSupporters: 340,
        profileLink: 'https://getmeachai.com/asfara.amir',
      },
    ],

    musicians: [
      {
        _id: '1',
        creatorName: 'Imad Tariq',
        creatorType: 'Guitarist',
        imgLink: '/assets/creators/musicians/Imad-Tariq.jpg',
        creatorInfo: 'Sessional player along with multiple original songs.',
        noOfSupporters: 103,
        profileLink: 'https://getmeachai.com/imaadt',
      },
      {
        _id: '2',
        creatorName: 'Hammad Tayyab',
        creatorType: 'Vocalists',
        imgLink: '/assets/creators/musicians/Hammad-Tayyab.jpg',
        creatorInfo: 'Aspiring vocalists and musician looking to go mainstream.',
        noOfSupporters: 230,
        profileLink: 'https://getmeachai.com/hammad',
      },
      {
        _id: '3',
        creatorName: 'Arsalan Qasim',
        creatorType: 'Drummer',
        imgLink: '/assets/creators/musicians/Arsalan-Qasim.jpg',
        creatorInfo: 'Passionate musician and a sessional drummer',
        noOfSupporters: 150,
        profileLink: 'https://getmeachai.com/arsalanqasim',
      },
    ],

    developers: [
      {
        _id: '1',
        creatorName: 'Syed Ilhan',
        creatorType: 'Software Engineer',
        imgLink: '/assets/creators/developers/Syed-Ilhan.jpg',
        creatorInfo: 'Developing high-end mobile apps',
        noOfSupporters: 56,
        profileLink: 'https://getmeachai.com/SyedIlhanShah',
      },
      {
        _id: '2',
        creatorName: 'Muzammil Bilwani',
        creatorType: 'Product Developer',
        imgLink: '/assets/creators/developers/Muzammil-Bilwani.jpg',
        creatorInfo: 'Commercial mobile developer with an experience of 6+ years',
        noOfSupporters: 72,
        profileLink: 'https://getmeachai.com/muzammilbilwani',
      },
      {
        _id: '3',
        creatorName: 'Muhamamd Musavir',
        creatorType: 'Front End Developer',
        imgLink: '/assets/creators/developers/Musavir-Ali.jpg',
        creatorInfo: 'Developing professional android apps',
        noOfSupporters: 20,
        profileLink: 'https://getmeachai.com/musa',
      },
    ],

    designers: [
      {
        _id: '1',
        creatorName: 'Hamza Ansari',
        creatorType: 'Graphics Designer',
        imgLink: '/assets/creators/designers/Hamza-Ansari.jpg',
        creatorInfo:
          'Designing brand identities and creative content for big and small scale brands',
        noOfSupporters: 48,
        profileLink: 'https://getmeachai.com/Hamza',
      },
      {
        _id: '2',
        creatorName: 'Maham Alam Khan',
        creatorType: 'UI Designer',
        imgLink: '/assets/creators/designers/Maham-Alam.jpg',
        creatorInfo: 'Professional UI designer with more than 5 years of experience',
        noOfSupporters: 120,
        profileLink: 'https://getmeachai.com/Maham',
      },
      {
        _id: '3',
        creatorName: 'Ali Zia Uddin',
        creatorType: 'Content Designer',
        imgLink: '/assets/creators/designers/Ali-Zia.jpg',
        creatorInfo: 'Tech savy person with a professional degree in engineering',
        noOfSupporters: 220,
        profileLink: 'https://getmeachai.com/AliZiauddin',
      },
    ],
  },
};

export default creatorsData;
