import type { NextPage } from 'next';
import Head from 'next/head';

import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Banner from '../components/home/Banner';
// import Benifits from '../components/home/Benifits';
import Creators from '../components/home/Creators';
import Donnations from '../components/home/Donnations';
import StartPage from '../components/home/StartPage';

const Home: NextPage = () => (
  <>
    <Head>
      <title>Get Me a Chai | Digital Support Platform</title>
    </Head>
    <Header />
    <Banner />
    <Creators />
    <Donnations />
    {/* <Benifits /> */}
    <StartPage />
    <Footer />
  </>
);

export default Home;
