import React from 'react';
import { useRouter } from 'next/router';
import Button from '../shared/Button';

function CreateButton() {
  const router = useRouter();
  const [username, setUsername] = React.useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    router.push(`/signup?username=${username}`);
  };

  return (
    <div className="create-btn-wrapper">
      <div className="d-flex flex-column align-items-center">
        <form className="create-btn" onSubmit={handleSubmit}>
          <div className="link-with-input">
            <h5>getmeachai.com/</h5>
            <input
              className="create-user-input"
              placeholder="Your name"
              value={username}
              onChange={handleChange}
            />
          </div>
          <Button size="large" type="submit">
            <div className="text-nowrap">Start my page</div>
          </Button>
        </form>
        <div className="create-btn-bottom-txt">
          <p>It&#39;s Free. Takes less than a minute</p>
        </div>
      </div>
    </div>
  );
}

function Banner() {
  return (
    <section className="bmt-banner-wrapper">
      <div className="container">
        <div className="bmt-banner">
          <div className="banner-left-section">
            <div className="banner-text-gt-md">
              <h1>
                <span>
                  Creativity driven by <br /> supporters!
                </span>
              </h1>
              <h4 className="w-75 mx-auto">
                What is Get me a Chai?
                <br />
                We provide creators a platform to be supported by their fans and followers.
              </h4>
            </div>
            <div className="banner-text-lt-md">
              <h1>
                Creativity driven by <br /> supporters!
              </h1>
              <h4>
                What is Get me a Chai?
                <br />
                We provide creators a platform to be supported by their fans and followers.
              </h4>
            </div>
            <CreateButton />
          </div>
        </div>
      </div>
      <div className="side-bubble right-side-bubble" />
    </section>
  );
}

export default Banner;
