import React from 'react';
import Link from 'next/link';
import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';

function Footer() {
  return (
    <footer className="container">
      <div className="bmt-footer">
        <div>
          <nav>
            <ul className="footer-links">
              <li>
                <Link href="/faq">FAQ</Link>
              </li>
              <li>
                <Link href="/contact">Contact</Link>
              </li>
              <li>
                <Link href="/terms">Terms</Link>
              </li>
              <li>
                <Link href="/refund-policy">Refund</Link>
              </li>
              <li>
                <Link href="/privacy-policy">Privacy</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div>
          <div className="footer-social-icons">
            <p>{new Date().getFullYear()} © Get me a chai</p>
            <FiFacebook />
            <FiInstagram />
            <FiTwitter />
          </div>
          <p className="mt-3" style={{ fontSize: 13 }}>
            Getmeachai, A project of Brandverse (Pvt) Ltd.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
