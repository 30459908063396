/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Image from 'next/image';

function Donnations() {
  return (
    <section className="donations-section">
      <div className="container">
        <div className="donations-subheading">
          <div>
            <h1>
              Give your audience
              <br />a chance to say thanks!
            </h1>
          </div>
          <div>
            <img className="donation-chai-img" src="/assets/chai-light.png" alt="" />
          </div>
        </div>
        <h4>
          In just a couple of taps, your fans can make the payment (get you a chai) and leave a
          message. They don&#39;t even have to create an account!
        </h4>
        <div className="messages-img-wrapper">
          <Image className="messages-img" src="/assets/messages.png" width={999} height={473} />
        </div>
      </div>
      {/* <div className="side-bubble left-side-bubble" /> */}
    </section>
  );
}

export default Donnations;
