import React from 'react';

export interface TabNavData {
  id: string;
  title: string;
}

interface TabNavItemProps extends TabNavData {
  selectedItemId: string;
  onSelect: (tabItemId: string) => void;
}

function TabNavItem(props: TabNavItemProps) {
  const { id, title, selectedItemId, onSelect } = props;

  const handleClick = () => {
    onSelect(id);
  };

  const isSelected = id === selectedItemId;

  return (
    <li
      className={`tab-nav-item ${isSelected ? 'active' : ''}`}
      role="presentation"
    >
      <button
        role="tab"
        className="tab-nav-link"
        aria-selected={isSelected}
        onClick={handleClick}
        type="button"
      >
        {title}
      </button>
    </li>
  );
}

export default TabNavItem;
