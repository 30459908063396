import useSWRImmutable from 'swr/immutable';
import { useAuth } from '../../contexts/AuthContext';
import { getLoggedinUserDetail } from '../../services/userService';

function useLoggedinUserDetail() {
  const { auth } = useAuth();

  const { data, error, isLoading, mutate } = useSWRImmutable(
    auth && !auth?.user.isAdmin ? getLoggedinUserDetail.key : null,
    getLoggedinUserDetail
  );

  return {
    data,
    error,
    isLoading,
    mutate,
  };
}

export default useLoggedinUserDetail;
