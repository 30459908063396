import React from 'react';
import { Creator } from '../../../types/common';
import CreatorsCard from './CreatorsCard';

interface IProps {
  creators: Creator[];
}

function CreatorsList({ creators }: IProps) {
  return (
    <div className={`creators-list ${creators.length < 4 ? 'creators-short-list' : ''}`}>
      {creators.map(item => (
        <CreatorsCard
          key={item._id}
          creatorName={item.creatorName}
          creatorType={item.creatorType}
          imgLink={item.imgLink}
          creatorInfo={item.creatorInfo}
          noOfSupporters={item.noOfSupporters}
          profileLink={item.profileLink}
        />
      ))}
    </div>
  );
}

export default CreatorsList;
