import React from 'react';

export interface TabContextType {
  defaultActiveId: string;
  selectedItemId: string;
  handleSelectItem: (item: string) => void;
}

const TabContext = React.createContext<TabContextType | null>(null);

interface IProps {
  defaultActiveItem: string;
  children: React.ReactNode;
}

export function TabContextProvider({ defaultActiveItem, children }: IProps) {
  const [selectedItem, setSelectedItem] = React.useState(defaultActiveItem);

  React.useEffect(() => {
    setSelectedItem(defaultActiveItem);
  }, [defaultActiveItem]);

  const handleSelectItem = (item: string) => {
    setSelectedItem(item);
  };

  const contextValues = React.useMemo(
    () => ({
      selectedItemId: selectedItem,
      defaultActiveId: defaultActiveItem,
      handleSelectItem,
    }),
    [selectedItem, defaultActiveItem]
  );

  return <TabContext.Provider value={contextValues}>{children}</TabContext.Provider>;
}

export default TabContext;
