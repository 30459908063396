/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import TabContext from './TabContext';

export interface TabProps {
  id: string;
  title: string;
  children: React.ReactNode;
}

function Tab(props: TabProps) {
  const context = React.useContext(TabContext);

  if (!context) {
    throw new Error('Tab component must be defined as child of Tabs component');
  }

  const { selectedItemId } = context;
  const { id, children } = props;

  const isSelected = selectedItemId === id;

  return isSelected ? <div className="tab-content">{children}</div> : null;
}

export default Tab;
