import React from 'react';
import creatorsData from '../../../data/creatorsData';
import Tabs from '../../shared/Tabs';
import CreatorsList from './CreatorsList';

function Creators() {
  return (
    <section className="container">
      <div className="creators-wrapper">
        <div className="creators-section-heading">
          <h3>Explore</h3>
          <h1>Creators.</h1>
        </div>
        <div>
          <Tabs defaultActiveItemId={creatorsData.creatorTypes[0].id}>
            {creatorsData.creatorTypes.map(item => (
              <Tabs.Tab id={item.id} title={item.title}>
                <CreatorsList creators={creatorsData.creators[item.id]} />
              </Tabs.Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </section>
  );
}

export default Creators;
