/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';

interface IProps {
  className?: string;
}

const Logo = ({ className = '' }: IProps) => (
  <div className={`bmt-logo py-3 ${className}`}>
    <Link href="/">
      <a>
        <img alt="logo" src="/logo.png" />
      </a>
    </Link>
  </div>
);

export default Logo;
