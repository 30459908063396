/* eslint-disable react/button-has-type */
import React from 'react';
import { COLORS } from '../../helpers/constants';
import Circles from './svgs/Circles';

export type ButtonProps = React.ComponentProps<'button'> & {
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'bordered' | 'rounded';
  size?: 'small' | 'normal' | 'large';
  className?: string;
  fullWidth?: boolean;
  isLoading?: boolean;
  active?: boolean;
  disabled?: boolean;
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    type = 'button',
    variant = 'primary',
    size = 'normal',
    className = '',
    isLoading = false,
    active = false,
    fullWidth,
    disabled,
    ...rest
  } = props;

  return (
    <button
      {...rest}
      className={`bmt-btn bmt-btn-${variant} bmt-btn-${size}${fullWidth ? ' w-100' : ''} ${
        active ? 'bmt-btn-active' : ''
      } ${className}`}
      type={type}
      ref={ref}
      disabled={isLoading || disabled}
    >
      {isLoading && <Circles color={COLORS.WHITE} height={18} width={18} />} {children}
    </button>
  );
});

export default Button;
